<template>
  <div class='event-lists'>
    <router-link
      v-for='item in block'
      :to='{
        name: "renginys",
        params: {
          id: item.slug,
        },
      }'
      :key="item.id"
    >
      <div class="one-event-block">
        <div class="one-event-image">
          <img
              v-lazy='api_url + item.renginio_nuotrauka.url'
              :data-srcset="srcsetUrl(api_url, item.renginio_nuotrauka)"
              :alt='item.renginio_nuotrauka.alternativeText || item.renginio_nuotrauka.name'
          ></div>
        <div class="one-event-info-wrapper">
          <div class="one-event-time">
            <span>{{ item.data }}</span>
          </div>
          <div class="one-event-name">
            <span>{{ item.renginio_pavadinimas }}</span>
          </div>
          <div class="one-event-location">
            <span>{{ item.miestas }}</span>
          </div>
        </div>

      </div>
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'Renginiai',
  data() {
    return {
      block: '',
      headers: { 'Content-Type': 'application/json' },
      api_url: process.env.VUE_APP_STRAPI_API_URL,
    };
  },
  methods: {
    srcsetUrl(url, image) {
      if (!image.formats) {
        return null;
      }
      const sizes = Object.keys(image.formats);
      return sizes.map((size) => `${url}${image.formats[size].url} ${image.formats[size].width}w`).join(', ');
    },
    uploadUrl() {
      const images = document.querySelectorAll('figure.image img');
      images.forEach((el) => {
        const source = el.src;
        const { length } = source;
        const findIndexOfUpload = source.match('upload').index;
        const strapiUrl = `${this.api_url}/`;
        const newSource = source.slice(findIndexOfUpload, length);
        el.src = strapiUrl + newSource;
      });
    },
    parseJSON(resp) {
      return (resp.json ? resp.json() : resp);
    },
    checkStatus(resp) {
      if (resp.status >= 200 && resp.status < 300) {
        return resp;
      }
      return this.parseJSON(resp).then(() => {
        throw resp;
      });
    },
    async fetchData() {
      try {
        const response = await fetch(`${this.api_url}/renginiais?_sort=data_laikas:ASC`, {
          method: 'GET',
          headers: this.headers,
        }).then(this.checkStatus)
          .then(this.parseJSON);
        this.block = response;
      } catch (error) {
        this.error = error;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  updated() {
    this.uploadUrl();
  }
};
</script>
