<template>
  <div class="app__header">
    <div class="logo-container navbar">
      <router-link class='navbar-logo' :to='{ path: "/" }'>
        <img
          width='108'
          height='150'
          src='@/assets/images/logo.png'
          alt='Lietuva skaito! logo'
        >
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Header',
  };
</script>