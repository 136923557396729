import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import Pagrindinis from './views/Pagrindinis.vue';
import Apie from './views/Apie.vue';
import Istorija from './views/Istorija.vue';
import Kontaktai from './views/Kontaktai.vue';
import Kalendorius from './views/Kalendorius.vue';
import Renginys from './views/Renginys.vue';
import Skaitome from './views/Skaitome.vue';
import Galerija from './views/Galerija.vue';
import Patarimai from './views/Patarimai.vue';
import Rekomenduojamos from './views/Rekomenduojamos.vue';
import Klaida from './views/Klaida.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'pagrindinis',
      component: Pagrindinis,
    },
    {
      path: '/apie',
      name: 'apie',
      component: Apie,
    },
    {
      path: '/istorija',
      name: 'istorija',
      component: Istorija,
    },
    {
      path: '/kontaktai',
      name: 'kontaktai',
      component: Kontaktai,
    },
    {
      path: '/renginiu-kalendorius',
      name: 'kalendorius',
      component: Kalendorius,
    },
    {
      path: '/renginiu-kalendorius/:id',
      name: 'renginys',
      component: Renginys,
    },
    {
      path: '/skaitome-vaikams',
      name: 'skaitome',
      component: Skaitome,
    },
    {
      path: '/galerija',
      name: 'galerija',
      component: Galerija,
    },
    {
      path: '/patarimai',
      name: 'patarimai',
      component: Patarimai,
    },
    {
      path: '/rekomenduojamos-knygos',
      name: 'rekomenduojamos',
      component: Rekomenduojamos,
    },
    {
      path: '*',
      name: 'klaida',
      component: Klaida,
    },
  ],
});
