
<template>
  <div v-if="error" class='container --container-realignment'>
    <Klaida />
  </div>
  <div v-else class="container --container-realignment">
    <div class="about_ltu-text">
      <div class="info--heading">
        <h1>{{ block.pavadinimas }}</h1>
      </div>
      <div class="about_ltu--info">
        <section class="info-text-block" v-for='item in antraste_media' :key="item.id">
          <h2>{{ item.antraste }}</h2>
          <div class="events-wrapper gallery">
            <vue-chocolat class='event-lists'>
            <a v-for='image in item.nuotraukos'
               :key='image.id'
               :href='api_url + image.url'
               class='gallery-link'
            >
              <div class="one-event-block">
                <div class="one-event-image">
                  <img v-lazy='api_url + image.url'
                    :data-srcset="srcsetUrl(api_url, image)"
                    :alt='image.alternativeText || image.name'>
                </div>
              </div>
            </a>
            </vue-chocolat>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Klaida from './Klaida.vue';
import vueChocolat from 'vue-chocolat';


export default {
  name: 'Galerija',
  components: {
    Klaida,
    'vue-chocolat': vueChocolat,
  },
  data() {
    return {
      block: '',
      antraste_media: '',
      error: null,
      headers: { 'Content-Type': 'application/json' },
      metaTags: 'Lietuva skaito!',
      api_url: process.env.VUE_APP_STRAPI_API_URL,
    };
  },
  metaInfo() {
    return {
      title: this.metaTags.title,
      // eslint-disable-next-line
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk}` : 'Lietuva skaito!';
      },
      meta: [
        { name: 'description', content: this.metaTags.description || 'Lietuva skaito!' },
        { name: 'og:title', content: `${this.metaTags.og_title}` || 'Lietuva skaito!' },
        { name: 'og:description', content: this.metaTags.og_description || 'Lietuva skaito!' },
        { name: 'og:image', content: this.metaTags.og_image || 'https://lietuvaskaito.lt/frontend/src/assets/logo/logo.svg' },
      ],
    };
  },
  methods: {
    srcsetUrl(url, image) {
      if (!image.formats) {
        return null;
      }
      const sizes = Object.keys(image.formats);
      return sizes.map((size) => `${url}${image.formats[size].url} ${image.formats[size].width}w`).join(', ');
    },
    parseJSON(resp) {
      return (resp.json ? resp.json() : resp);
    },
    checkStatus(resp) {
      if (resp.status >= 200 && resp.status < 300) {
        return resp;
      }
      return this.parseJSON(resp).then(() => {
        throw resp;
      });
    },
    async fetchData() {
      try {
        const response = await fetch(`${this.api_url}/galerija`, {
          method: 'GET',
          headers: this.headers,
        }).then(this.checkStatus)
          .then(this.parseJSON);
        this.block = response;
        this.antraste_media = response.antraste_media;
        this.metaTags = response.meta_duomenys;
      } catch (error) {
        this.error = error;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
  @import '../assets/css/events.css';
</style>
