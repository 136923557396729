
<template>
  <div v-if="error" class='container --container-realignment'>
    <Klaida />
  </div>
  <div v-else class="container --container-realignment">
    <div class="info-text">
      <div class="info--heading">
        <h1>{{ block.pavadinimas }}</h1>
      </div>
      <div class="info-text-block" v-if='block.tekstas' v-html='block.tekstas'></div>
    </div>
  </div>
</template>

<script>
import Klaida from './Klaida.vue';

export default {
  name: 'Rekomenduojamos',
  components: {
    Klaida,
  },
  data() {
    return {
      block: '',
      error: null,
      headers: { 'Content-Type': 'application/json' },
      metaTags: 'Lietuva skaito!',
      api_url: process.env.VUE_APP_STRAPI_API_URL,
    };
  },
  metaInfo() {
    return {
      title: this.metaTags.title,
      // eslint-disable-next-line
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk}` : 'Lietuva skaito!';
      },
      meta: [
        { name: 'description', content: this.metaTags.description || 'Lietuva skaito!' },
        { name: 'og:title', content: `${this.metaTags.og_title}` || 'Lietuva skaito!' },
        { name: 'og:description', content: this.metaTags.og_description || 'Lietuva skaito!' },
        { name: 'og:image', content: this.metaTags.og_image || 'https://lietuvaskaito.lt/frontend/src/assets/logo/logo.svg' },
      ],
    };
  },
  methods: {
    uploadUrl() {
      const images = document.querySelectorAll('figure.image img');
      images.forEach((el) => {
        const source = el.src;
        const { length } = source;
        const findIndexOfUpload = source.match('upload').index;
        const strapiUrl = `${this.api_url}/`;
        const newSource = source.slice(findIndexOfUpload, length);


        el.src = strapiUrl + newSource;
      });
    },
    parseJSON(resp) {
      return (resp.json ? resp.json() : resp);
    },
    checkStatus(resp) {
      if (resp.status >= 200 && resp.status < 300) {
        return resp;
      }
      return this.parseJSON(resp).then(() => {
        throw resp;
      });
    },
    async fetchData() {
      try {
        const response = await fetch(`${this.api_url}/rekomenduojamos-knygos`, {
          method: 'GET',
          headers: this.headers,
        }).then(this.checkStatus)
          .then(this.parseJSON);
        this.block = response;
        this.metaTags = response.meta_duomenys;
      } catch (error) {
        this.error = error;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  updated() {
    this.uploadUrl();
  }
};
</script>
