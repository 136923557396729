<template>
  <section class="sponsors-list">
    <div class="wrapper">
      <div v-for='item in remejai' :key="item.id" class='placeholder-div'>
      <h2>{{ item.pavadinimas }}</h2>
      <ul>
        <li v-for='subitem in item.remejas_su_nuoroda' :key="subitem.id">
          <a :href="subitem.remejo_nuoroda" target="_blank">
            <img
              class='remejai-img'
              v-lazy='api_url + subitem.remejo_logotipas.url'
              :data-srcset="srcsetUrl(api_url, subitem.remejo_logotipas)"
              :alt='subitem.remejo_logotipas.alternativeText || subitem.remejo_logotipas.name'
            >
          </a>
        </li>
      </ul>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Sponsors',
  data() {
    return {
      remejai: '',
      error: null,
      headers: { 'Content-Type': 'application/json' },
      api_url: process.env.VUE_APP_STRAPI_API_URL,
    };
  },
  methods: {
    srcsetUrl(url, image) {
      if (!image.formats) {
        return null;
      }
      const sizes = Object.keys(image.formats);
      return sizes.map((size) => `${url}${image.formats[size].url} ${image.formats[size].width}w`).join(', ');
    },
    parseJSON(resp) {
      return (resp.json ? resp.json() : resp);
    },
    checkStatus(resp) {
      if (resp.status >= 200 && resp.status < 300) {
        return resp;
      }
      return this.parseJSON(resp).then(() => {
        throw resp;
      });
    },
    async fetchData() {
      try {
        const response = await fetch(`${this.api_url}/remejai`, {
          method: 'GET',
          headers: this.headers,
        }).then(this.checkStatus)
          .then(this.parseJSON);
        this.remejai = response.organizatoriai_remejai;
      } catch (error) {
        this.error = error;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>