<template>
  <section class="secondary-nav--navbar">
    <div class="wrapper">
      <router-link :to="{ name: 'apie' }">APIE „LIETUVA SKAITO!“</router-link>
      <router-link :to="{ name: 'istorija' }">ISTORIJA</router-link>
      <router-link :to="{ name: 'kontaktai' }">KONTAKTAI</router-link>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'SecondaryNav',
  };
</script>