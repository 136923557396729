<template>
  <nav class='main-nav container'>
    <div class='full-overlay' :class='{ opened: isOpened }' @click='clickNav'></div>
    <div class='label' :class='{ opened: isOpened }' @click='clickNav'>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <ul class='navigation-list'>
      <li class='item' @click='clickNav'>
        <router-link :to="{ name: 'kalendorius' }" class="nav-button">
          <svg class='nav-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.12 63.54">
            <rect stroke="#1d1d1b" fill="#fff" stroke-miterlimit="10" x="0.5" y="0.5" width="50.12" height="62.54"/>
            <line stroke="#1d1d1b" fill="none" stroke-miterlimit="10" x1="0.5" y1="17.8" x2="50.62" y2="17.8"/>
            <line stroke="#1d1d1b" fill="none" stroke-miterlimit="10" x1="17.5" y1="29.24" x2="44.52" y2="29.24"/>
            <line stroke="#1d1d1b" fill="none" stroke-miterlimit="10" x1="17.5" y1="40.39" x2="44.52" y2="40.39"/>
            <line stroke="#1d1d1b" fill="none" stroke-miterlimit="10" x1="17.5" y1="51.74" x2="44.52" y2="51.74"/>
            <rect fill="#1d1d1b" x="0.5" y="0.5" width="50.12" height="17.3"/>
            <line stroke="#fff" fill="none" stroke-miterlimit="10" x1="17.5" y1="8.59" x2="44.52" y2="8.59"/>
            <path fill="#fff" d="M7.52,10.93H9V8.14c0-.26,0-.52,0-.52h0A1.45,1.45,0,0,1,8.68,8l-.41.36-.94-1,1.8-1.69h1.42v5.29H12v1.4H7.52Z"/>
          </svg>
          <div>RENGINIŲ KALENDORIUS</div>
        </router-link>
      </li>
      <li class='item' @click='clickNav'>
        <router-link :to="{ name: 'skaitome' }" class="nav-button">
          <svg class='nav-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.47 77.11">
            <polygon stroke="#1d1d1b" fill="#fff" stroke-miterlimit="10" points="0.5 13.4 28.74 32.33 56.97 13.4 56.97 57.58 28.74 76.51 0.5 57.58 0.5 13.4"/>
            <circle stroke="#1d1d1b" fill="#1d1d1b" stroke-miterlimit="10" cx="18.95" cy="11.49" r="10.99"/>
            <circle stroke="#1d1d1b" fill="#1d1d1b" stroke-miterlimit="10" cx="40.72" cy="15.79" r="6.68"/>
            <line stroke="#1d1d1b" fill="#fff" stroke-miterlimit="10" x1="28.74" y1="32.33" x2="28.74" y2="76.51"/>
          </svg>
          <div>SKAITOME VAIKAMS</div>
        </router-link>
      </li>
      <li class='item' @click='clickNav'>
        <router-link :to="{ name: 'galerija' }" class="nav-button">
          <svg class='nav-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.69 56.5">
            <rect stroke="#1d1d1b" fill="#fff" stroke-miterlimit="10" x="0.5" y="0.5" width="44.66" height="31.53"/>
            <rect stroke="#1d1d1b" fill="#fff" stroke-miterlimit="10" x="22.83" y="24.48" width="44.66" height="31.53"/>
            <rect stroke="#1d1d1b" fill="#fff" stroke-miterlimit="10" x="52.52" y="8.71" width="44.66" height="31.53"/>
            <polyline stroke="#1d1d1b" fill="#1d1d1b" stroke-miterlimit="10" points="58.4 40.24 68.94 17.12 80.88 40.24"/>
            <circle stroke="#1d1d1b" fill="#1d1d1b" stroke-miterlimit="10" cx="84.89" cy="21.04" r="5.49"/>
          </svg>
          <div>GALERIJA</div>
        </router-link>
      </li>
      <li class='item' @click='clickNav'>
        <router-link :to="{ name: 'patarimai' }" class="nav-button">
          <svg class='nav-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.13 68.25">
            <rect stroke="#1d1d1b" fill="#fff" stroke-miterlimit="10" x="-4.93" y="15.19" width="57.99" height="47.13" transform="translate(62.82 14.69) rotate(90)"/>
            <rect stroke="#1d1d1b" fill="#fff" stroke-miterlimit="10" x="-4.93" y="5.93" width="57.99" height="47.13" transform="translate(53.56 5.43) rotate(90)"/>
            <text font-size="31.44px;" font-weight="900" fill="#1d1d1b" transform="translate(14.1 32.77)">P</text>
            <line stroke="#1d1d1b" fill="none" stroke-miterlimit="10" x1="6.19" y1="50.22" x2="41.93" y2="50.22"/>
            <line stroke="#1d1d1b" fill="none" stroke-miterlimit="10" x1="6.19" y1="45.55" x2="41.93" y2="45.55"/>
            <line stroke="#1d1d1b" fill="none" stroke-miterlimit="10" x1="6.19" y1="40.88" x2="41.93" y2="40.88"/>
          </svg>
          <div>PATARIMAI</div>
        </router-link>
      </li>
      <li class='item' @click='clickNav'>
        <router-link :to="{ name: 'rekomenduojamos' }" class="nav-button">
          <svg class='nav-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.31 84.96">
            <polygon stroke="#1d1d1b" fill="#fff" stroke-miterlimit="10" points="0.82 0.94 22.15 15.24 43.48 0.94 43.48 34.31 22.15 48.62 0.82 34.31 0.82 0.94"/>
            <line stroke="#1d1d1b" fill="none" stroke-miterlimit="10" x1="22.15" y1="15.24" x2="22.15" y2="48.62"/>
            <rect stroke="#1d1d1b" fill="#1d1d1b" stroke-miterlimit="10" x="0.5" y="77.14" width="43.31" height="7.32"/>
            <rect stroke="#1d1d1b" fill="#fff" stroke-miterlimit="10" x="0.5" y="66.19" width="43.31" height="7.32"/>
            <rect stroke="#1d1d1b" fill="#fff" stroke-miterlimit="10" x="0.5" y="55.25" width="43.31" height="7.32"/>
          </svg>
          <div>REKOMENDUOJAMOS KNYGOS</div>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
  export default {
    name: 'Nav',
    data() {
      return {
        isOpened: false,
      };
    },
    methods: {
      clickNav() {
        const media_query = window.matchMedia('(max-width: 770px)')
        if (media_query.matches) {
          this.isOpened = !this.isOpened;
          document.body.classList.toggle('fixed');
        }
      },
    },
  };
</script>
