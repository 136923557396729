<template>
  <div id="app" class='app'>
    <Header />
    <div class="app__content">
      <main role="main">
        <Nav />
        <keep-alive>
          <router-view :key="$route.path"></router-view>
        </keep-alive>
        <SecondaryNav />
        <Sponsors />
      </main>
    </div>
    <Footer />
  </div>
</template>

<script>

import Header from './components/Header.vue';
import Nav from './components/Nav.vue';
import SecondaryNav from './components/SecondaryNav.vue';
import Sponsors from './components/Sponsors.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: { Header, Nav, SecondaryNav, Sponsors, Footer },
};

</script>

<style>
  @import './assets/css/bootstrap.min.css';
  @import './assets/css/main.css';
</style>

