<template>
  <div v-if="error" class='container --container-realignment'>
    <Klaida />
  </div>
  <div v-else class="container --container-realignment">
    <div class="about_ltu-text">
      <div class="info--heading">
        <h1>{{ block.pavadinimas }}</h1>
      </div>
      <div class="about_ltu--info">
        <section class="info-text-block" v-for='item in antraste_video' :key="item.id">
          <h2>{{ item.antraste }}</h2>
          <div class="events-wrapper gallery">
            <LazyYoutubeVideo
              style="max-width: 720px; width: 100%"
              :src="item.youtube_video"
            />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Klaida from './Klaida.vue';
import LazyYoutubeVideo from 'vue-lazy-youtube-video';
import 'vue-lazy-youtube-video/dist/style.css';

export default {
  name: 'Skaitome',
  components: {
    Klaida,
    LazyYoutubeVideo,
  },
  data() {
    return {
      block: '',
      antraste_video: '',
      error: null,
      headers: { 'Content-Type': 'application/json' },
      metaTags: 'Lietuva skaito!',
      api_url: process.env.VUE_APP_STRAPI_API_URL,
    };
  },
  metaInfo() {
    return {
      title: this.metaTags.title,
      // eslint-disable-next-line
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk}` : 'Lietuva skaito!';
      },
      meta: [
        { name: 'description', content: this.metaTags.description || 'Lietuva skaito!' },
        { name: 'og:title', content: `${this.metaTags.og_title}` || 'Lietuva skaito!' },
        { name: 'og:description', content: this.metaTags.og_description || 'Lietuva skaito!' },
        { name: 'og:image', content: this.metaTags.og_image || 'https://lietuvaskaito.lt/frontend/src/assets/logo/logo.svg' },
      ],
    };
  },
  methods: {
    parseJSON(resp) {
      return (resp.json ? resp.json() : resp);
    },
    checkStatus(resp) {
      if (resp.status >= 200 && resp.status < 300) {
        return resp;
      }
      return this.parseJSON(resp).then(() => {
        throw resp;
      });
    },
    async fetchData() {
      try {
        const response = await fetch(`${this.api_url}/skaitome-vaikams`, {
          method: 'GET',
          headers: this.headers,
        }).then(this.checkStatus)
          .then(this.parseJSON);
        this.block = response;
        this.antraste_video = response.antraste_video;
        this.metaTags = response.meta_duomenys;
      } catch (error) {
        this.error = error;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
  @import '../assets/css/events.css';
</style>