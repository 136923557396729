<template>
  <div v-if="error" class='main-gallery'>
    <Klaida />
  </div>
  <section v-else class='main-gallery'>
    <div class="wrapper">
      <!-- Additional required wrapper -->
      <swiper class="swiper" :options="swiperOption">
        <swiper-slide v-if='!pagrindinis'>
          <div class="img-box">
            <div class="img-padding">
              <div class="img-wrapper">
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide v-else v-for='image in pagrindinis' :key='image.id'>
          <div class="img-box">
            <div class="img-padding">
              <div class="img-wrapper">
                <img v-lazy='api_url + image.url'
                  :data-srcset="srcsetUrl(api_url, image)"
                  :alt='image.alternativeText || image.name'>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <div class="subcarousel-text" v-if='tekstas' v-html='tekstas'></div>
    </div>
  </section>
</template>

<script>
import Klaida from './Klaida.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
  name: 'Pagrindinis',
  components: {
    Klaida,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      pagrindinis: '',
      tekstas: '',
      error: null,
      headers: { 'Content-Type': 'application/json' },
      metaTags: 'Lietuva skaito!',
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      swiperOption: {
        updateOnWindowResize: true,
        slidesPerView: 1.25,
        spaceBetween: 20,
        centeredSlides: true,
        autoplay: {
          delay: 5000,
        },
        breakpoints: {
          601: {
            slidesPerView: 1,
            spaceBetween: 50,
          }
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination'
        },
      }
    };
  },
  metaInfo() {
    return {
      title: this.metaTags.title,
      // eslint-disable-next-line
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk}` : 'Lietuva skaito!';
      },
      meta: [
        { name: 'description', content: this.metaTags.description || 'Lietuva skaito!' },
        { name: 'og:title', content: `${this.metaTags.og_title}` || 'Lietuva skaito!' },
        { name: 'og:description', content: this.metaTags.og_description || 'Lietuva skaito!' },
        { name: 'og:image', content: this.metaTags.og_image || 'https://lietuvaskaito.lt/frontend/src/assets/logo/logo.svg' },
      ],
    };
  },
  methods: {
    srcsetUrl(url, image) {
      if (!image.formats) {
        return null;
      }
      const sizes = Object.keys(image.formats);
      return sizes.map((size) => `${url}${image.formats[size].url} ${image.formats[size].width}w`).join(', ');
    },
    parseJSON(resp) {
      return (resp.json ? resp.json() : resp);
    },
    checkStatus(resp) {
      if (resp.status >= 200 && resp.status < 300) {
        return resp;
      }
      return this.parseJSON(resp).then(() => {
        throw resp;
      });
    },
    async fetchData() {
      try {
        const response = await fetch(`${this.api_url}`, {
          method: 'GET',
          headers: this.headers,
        }).then(this.checkStatus)
          .then(this.parseJSON);
        this.pagrindinis = response.karusele;
        this.tekstas = response.tekstas;
        this.metaTags = response.meta_duomenys;
      } catch (error) {
        this.error = error;
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>